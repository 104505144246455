import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Footer from "../components/footer"

const Limpieza = () => (
  <Layout>
    <SEO title="Limpieza y armonización energética" />

    <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
      <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
        Limpieza y armonización energética
      </h2>
      <div className="w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/3 rounded-lg p-5">
          <img
            src="https://www.datocms-assets.com/41997/1613497921-pendulo1.jpg"
            alt="Limpieza y armonización energética"
            className=""
          />
          <p className="text-sm text-yellow-100 p-0 py-4">
            Duración de la sesión: 45 minutos
          </p>
          <p className="text-sm text-yellow-100 p-0 pt-0">
            Técnicas: Chamánicas con Péndulo Cuántico y cristales.
          </p>
          <p className="text-sm text-yellow-100 p-0 pt-3">
            <Link className="underline">Agendar una cita</Link>
          </p>
        </div>

        <div className="w-full lg:w-2/3 mt-5 lg:mt-0">
          <p>
            Las herramientas poderosas que utilizo para armonizar tu energía son
            una mezcla de técnicas chamánicas, con la utilización de cristales y
            PENDULO CUÁNTICO &#174;.
          </p>

          <p>
            Nuestro campo energético está constantemente influenciado por
            energías de los espacios que habitamos, la gente con que nos
            comunicamos, nuestros pensamientos, emociones, etc. Limpiar y
            Armonizar nuestra energía es muy importante para tener una vida más
            balanceada, accesar a nuestra creatividad, aclarar nuestra mente,
            tener la energía necesaria para los proyectos que nos planteamos,
            movilizar la energía estancada, equilibrar nuestras emociones y
            abrirnos al flujo de abundancia y conciencia universal.
          </p>

          <p>
            El ser humano es un complejo conjunto de energía, emociones y
            estructuras físicas, que desde la visión holística integradora, cada
            uno de estos 3 pilares, están estrechamente vinculados unos a otros,
            y mediante esta técnica, lo que se busca, es alinear esos 3 ejes,
            para una mayor calidad de vida espiritual, emocional y física.
          </p>

          <p>
            Es una terapia energética aplicada al cuerpo físico y energético del
            consultante. Mediante diferentes técnicas especificas, se buscará
            reequilibrar el circuito energético y los chackras, como así también
            las capas áuricas, que son las primeras en contaminarse por las
            vibraciones y frecuencias energéticas externas, y que son ajenas a
            el.
          </p>

          <p>
            Una sesión de péndulo cuántico aporta alivio, equilibrio y mucha
            sensación de bienestar. El campo energético rara vez es atendido y
            escuchado, y es por ello, que cuando el consultante comienza a
            ocuparse de esta área vital de su vida, los resultados son
            tangibles, y los beneficios son muchos. (claramente esto NO
            reemplaza ningún tratamiento medico, siempre es vital recurrir a un
            medico de cabecera).
          </p>

          <p>¿Cómo saber si necesito de este tipo de terapia?</p>

          <p>
            Si siente sensación de cansancio y pesadez, le es difícil hacer
            hacer cambios. Se enferman con frecuencia se sienten sin ganas de
            hacer nada, sensación de desgaste y desarmonía.
          </p>

          <p>
            Soy Terapeuta certificada en Péndulo Cuántico &#174; y Gemoterapia
            de la Escuela Pandora y estudié Chamanismo en el Umbral de la
            Montaña.
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default Limpieza
